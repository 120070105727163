import React from 'react'
import Button from "@material-ui/core/Button";
import RefreshIcon from '@material-ui/icons/Refresh';

const RefreshButton = ({classes}) => {

  const [disabled, setDisabled] = React.useState(false);

  const refreshPage = e => {
    e.preventDefault();
    setDisabled(true);
    window.location.reload();
  }

  return(
    <Button onClick={refreshPage} 
            size="small"
            className={`no-outline ${classes}`}
            startIcon={<RefreshIcon />}
            disabled={disabled}
            >
      Refresh
    </Button>
  )
}

export default RefreshButton
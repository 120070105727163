import React from 'react'
import axios from 'axios'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CurrencyFormat from 'react-currency-format';
import CircularProgress from "@material-ui/core/CircularProgress";

class Subscription extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subscription: {},
      isLoaded: false
    };
  }

  componentDidMount(){
    axios.get('/subscription.json')
      .then(res => {
      this.setState({ subscription: res.data,
                      isLoaded: true })
    })
      .catch(error => {
        console.log(error)
    })
  }

  render(){
    
    const { subscription, isLoaded } = this.state

    return(
      <div className="subscription" style={{paddingTop: 100}}>

        <div className="header">Your Current Plan</div>
        
        <div className="clean-box">
          { isLoaded === true ? 
          <CurrencyFormat className="price" value={subscription.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          : <CircularProgress className="gray-loader mb-4 mt-4" /> }
          <div className="label">per month</div>
        </div>

        <div className="info-area">
          <InfoOutlinedIcon className="mr-1" />
          To cancel your plan, please visit the apps section of your Shopify admin. 
        </div>
      </div>

    )
  }
}

export default Subscription
import React from 'react'
import CurrencyFormat from 'react-currency-format';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LaunchIcon from '@material-ui/icons/Launch';
import ReactTimeAgo from 'react-time-ago'
import Link from '@material-ui/core/Link';
import TruncateString from 'react-truncate-string'
import ScheduleIcon from '@material-ui/icons/Schedule';

const ActivityItem = ({order, domain}) => {
  
  const order_count = order.items_ordered

  const Price = <CurrencyFormat decimalScale={2} 
                                fixedDecimalScale={true} 
                                style={{fontWeight: 'bold'}} 
                                value={order.total_price} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'$'} />

  const order_activity = `${order.customer_name} purchased ${order_count === 1 ? '1 item' : order_count+' items' } totaling `
  const first_item = order.order_hash["line_items"][0]
  const product_title = first_item['title']

  return(
    <div>
      <hr />

      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor: '#85BB65'}}>
              <AttachMoneyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={
            <React.Fragment>
              {order_activity} {Price}
            </React.Fragment>
          } secondary={
            <React.Fragment>
              <br className="show-mobile" />
              {first_item["quantity"]} x {product_title.length > 25 ? product_title.substring(0,24) + '...' : product_title} 
              <Link href={`https://${domain}/admin/orders/${order.shopify_id}`}
                    rel="noopener"
                    target="_blank"
                    className="custom-blue-link ml-1"
                    > <br className="show-mobile" />
                View Order <LaunchIcon style={{fontSize: '12pt'}} />
              </Link>
              <div className="show-mobile">
                <br />
                <ScheduleIcon style={{fontSize: '12pt'}} /> <ReactTimeAgo date={order.placed_at}/>
              </div>
            </React.Fragment>
          } />
          <ListItemSecondaryAction className="hide-mobile" style={{color: 'gray', fontSize: '10pt'}} >
            <ScheduleIcon style={{fontSize: '12pt'}} /> <ReactTimeAgo date={order.placed_at}/>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}

export default ActivityItem
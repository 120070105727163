import React, {Component} from "react";
import CampaignTable from "./CampaignTable.jsx";
import {Container, Typography} from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshButton from './RefreshButton'

export class AllCampaigns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignList: [],
            isLoaded:false
        };
    }

    componentDidMount() {
        fetch("/campaigns", {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then( result => {return result.json()})
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        campaignList: result.campaigns
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (result) => {
                    this.setState({
                        isLoaded: true
                    });
                }
            );
    }

    refreshPage = e => {
      e.preventDefault();
      this.setState({isLoaded: false})
      window.location.reload();
    }

    render() {
        if (this.state.isLoaded && this.state.campaignList.length === 0) {
            return (
                <div className="perfectly-centered text-center">

                  <div>
                    <ListIcon className="no-links" />
                  </div>

                  <h3>Once you create your first campaign, it will show up here.</h3>
                  
                </div>
            )
        } else if (this.state.isLoaded) {
            return (
                <Container style={{width: "99%"}} className="campaign-index" maxWidth="lg">
                    <Typography variant="h2">
                      All Campaigns
                      <span className="float-right">
                        <RefreshButton />
                      </span>
                    </Typography>
                    <CampaignTable  campaignList={this.state.campaignList}/>
                </Container>
            )
        } else{
            return (
              <div className="loading-area container text-center">
                <CircularProgress className="gray-loader" />
              </div>
            )
        }

        }
}

export default AllCampaigns;


import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Drawer from "../components/NavDrawer";
import AllCampaigns from "../components/AllCampaigns";
import ShowCampaign from "../components/ShowCampaign";
import Subscription from "../components/Subscription";
import Profile from "../components/Profile";

export default (
    <Router>
        <Switch>
            <Route path="/campaigns/:id" exact={true} >
                <Drawer
                    wrapper={<ShowCampaign/>}
                    selected={'all'}
                />
            </Route>

            <Route path="/campaigns" exact={true}>
                <Drawer
                    wrapper={<AllCampaigns/>}
                    selected={'all'}
                />
            </Route>

            <Route path="/campaigns/new" exact={true} />

            <Route path="/subscription">
                <Drawer
                    wrapper={<Subscription/>}
                    selected={'subscription'}
                />
            </Route>

            <Route path="/profile" exact={true}>
                <Drawer
                    wrapper={<Profile/>}
                    selected={'profile'}
                />
            </Route>
        </Switch>
    </Router>


);
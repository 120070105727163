import React from "react";
import { render } from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from "../components/App";
import axios from 'axios';
import JavascriptTimeAgo from 'javascript-time-ago'
 
// The desired locales.
import en from 'javascript-time-ago/locale/en'

// Initialize the desired locales.
JavascriptTimeAgo.locale(en)

const csrf = document.querySelector('[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf

document.addEventListener("DOMContentLoaded", () => {

    const csrfToken = document.querySelector('[name="csrf-token"]').content;
    window.sessionStorage.setItem("csrf", csrfToken);

    render(
       <App/> ,
        document.body.appendChild(document.createElement("div"))
    );
});
import React from 'react'
import CurrencyFormat from 'react-currency-format';
import ConversionRate from './ConversionRate'
import Link from '@material-ui/core/Link';
import axios from 'axios'

const StatBox = ({campaign, updateGraph, currentLabel}) => {

  const clickOrderHandler = e => {
    e.preventDefault();



    const label = "30-Day Orders"
    const color = "#45d14e"

    if (currentLabel === label) return

    axios.get(`/campaigns/${campaign.id}/monthly_orders`)
      .then(res => {
        updateGraph({
          label: label, 
          color: color, 
          data: res.data.monthly_orders
        })
      })
      .catch(err => {
        console.log(err)
        updateGraph({
          label: label, 
          color: color, 
          data: null
        })
      })
  }

  const clickClicksHandler = e => {
    e.preventDefault();

    const label = '30-Day Clicks'
    const color = '#EC932F'

    if (currentLabel === label) return
    
    axios.get(`/campaigns/${campaign.id}/monthly_clicks`)
    .then(res => {
      updateGraph({
        label: label, 
        color: color, 
        data: res.data.monthly_clicks
      })
    })
    .catch(err => {
      console.log(err)
      updateGraph({
        label: label, 
        color: color, 
        data: null
      })
    })
  }

  const clickRevenueHandler = e => {
    e.preventDefault();

    const label = '30-Day Revenue'
    const color = '#3184c4'

    if (currentLabel === label) return

    axios.get(`/campaigns/${campaign.id}/monthly_revenue`)
      .then(res => {
        updateGraph({
          label: label, 
          color: color, 
          data: res.data.monthly_revenue
        })
      })
      .catch(err => {
        console.log(err)
        updateGraph({
          label: label, 
          color: color, 
          data: null
        })
      })
    }

  return(
    <div className="clean-box p-0">
      <div className="row text-center stat-box">
          <div className="col-md-3">
            <Link href="" onClick={clickClicksHandler} className="no-style-link">
              <div className="stat-area border-yellow">
                <div className="number">
                  {campaign.short_link_id !== null ? campaign.clicks : '--'}
                </div>
                <div className="label">Clicks</div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link href="" onClick={clickOrderHandler} className="no-style-link">
              <div className="stat-area border-green">
                <div className="number">{campaign.orders_count}</div>
                <div className="label">Orders</div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link href="" onClick={clickRevenueHandler} className="no-style-link">
              <div className="stat-area border-blue">
                <CurrencyFormat decimalScale={2} fixedDecimalScale={true} className="number" value={campaign.revenue} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                <div className="label">Revenue</div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
              <div className="stat-area border-red">
                  <div className="number">
                    { campaign.short_link_id === null ? '--' : <ConversionRate clicks={campaign.clicks} orders={campaign.orders_count} /> }
                  </div>
                  <div className="label">Conversion</div>
              </div>
          </div>
      </div>
  </div>
  );
}

export default StatBox
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import CurrencyFormat from 'react-currency-format';
import Hidden from "@material-ui/core/Hidden";
const columns = [
    { id: 'name', label: 'Name', secondaryKey: 'created_at', showOnMobile: true },
    { id: 'source', label: 'Source', showOnMobile: false },
    { id: 'clicks', label: 'Clicks', align: 'center', showOnMobile: true },
    { id: 'revenue', label: 'Revenue', align: 'center', showOnMobile: true, format: value => value.toLocaleString() },
    { id: 'activity', label: '', align: 'center', showOnMobile: true },
    { id: 'view', label: 'View', align: 'center', showOnMobile: true, format: value => value.toLocaleString() },
];


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        overflow: 'auto',
    },
});

export default function CampaignTable(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const campaigns = props.campaignList;
    const moment = require('moment');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                column.showOnMobile ?
                                <TableCell
                                key={column.id}
                                align={column.align}
                                >
                                {column.label}
                                </TableCell> :
                                    <Hidden mdDown>
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                    </Hidden>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(campaign => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={campaign.id}>
                                    {columns.map(column => {
                                        
                                        const value = campaign[column.id];
                                        const secondaryKey = campaign[column.secondaryKey];
                                        const one_day_ago = new Date().getTime() - (1 * 24 * 60 * 60 * 1000)

                                        if (column.id === "view") {
                                            return (
                                              <TableCell key={column.id} align={column.align}>
                                                <Button variant="contained" size="large" component={Link} className="custom-blue-btn" to={{pathname: '/campaigns/'+campaign.id}}>
                                                  View
                                                </Button>
                                              </TableCell>
                                            );
                                        } else if (column.id === "revenue") {
                                            return(
                                                <TableCell key={column.id} align={column.align}>
                                                    <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>
                                              );
                                        } else if (column.id === "source") {
                                          return(
                                              <Hidden mdDown>
                                            <TableCell key={column.id} align={column.align}>
                                                <div className="badge badge-info">{value}</div>
                                            </TableCell>
                                              </Hidden>
                                          );
                                        } else if (column.id === "name") {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    <div>
                                                    <small className="text-muted">{moment(new Date(secondaryKey)).format("MMM DD, YYYY")}</small>
                                                    </div>
                                                </TableCell>
                                            );
                                        } else if (column.id === "activity" && moment(new Date(campaign.last_order_at)) >= one_day_ago ) {
                                          return(
                                            <TableCell key={column.id} align={column.align}>
                                              <div className="badge badge-warning">Recent Activity</div>
                                            </TableCell>
                                          );
                                        } else {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={campaigns.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import axios from 'axios'
import ee from 'event-emitter'

const deleteEmitter = new ee();

export const openDeleteDialog = (campaign_id) => {
  deleteEmitter.emit('dialog', campaign_id)
}

class DeleteConfirmation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      campaign_id: ''
    }

    deleteEmitter.on('dialog', (campaign_id) => {
      this.handleClickOpen(campaign_id);
    })
  }

  handleClickOpen = (campaign_id) => {
    this.setState({ 
      open: true, 
      campaign_id: campaign_id 
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
      this.setState({ loading: true });
      axios.delete('/campaigns/'+this.state.campaign_id)
        .then(res => {
          window.location.href = '/campaigns';
          
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false });
        })
    }

  render(){
    
    const { open, loading } = this.state

    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
        >
          <DialogContent>
            <DialogContentText style={{fontSize: '14pt', color: 'rgba(0, 0, 0, 0.87)'}}>
              Are you sure? <br /><span style={{color: 'gray'}}>Deleting a campaign cannot be undone.</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} className="no-outline">
              Cancel
            </Button>
            <Button disabled={loading} onClick={this.handleDelete} className="no-outline">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DeleteConfirmation
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SnackBar, { showSnack } from './SnackBar'
import axios from 'axios'
import ee from 'event-emitter'

const clickEmitter = new ee();

export const openFeedback = () => {
  clickEmitter.emit('openDialog')
}

class FeedbackDialog extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      open: false,
      body: '',
      submitDisabled: false
    }

    clickEmitter.on('openDialog', () => {
      this.handleClickOpen();
    });
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    })
  };

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  changeHandler = e => {
    this.setState({body: e.target.value})
  }

  submitHandler = (e) => {
    e.preventDefault();
    
    this.setState({submitDisabled: true})

    axios.post('/feature-request', { feature_request: { body: this.state.body } })
      .then(res => {
        this.setState({submitDisabled: false, open: false})
        showSnack(res.data.message, 'success')
      })
      .catch(err => {
        this.setState({submitDisabled: false})
      })
  } 

  render(){

    const { open, submitDisabled } = this.state 

    return (
      <div>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          
          <form onSubmit={this.submitHandler}>
            <DialogContent>
              <DialogContentText>
                <h3 style={{color: 'black'}}>Feature Request</h3>
                <p>Please use this form to request features or leave general feedback. Your opinion matters and will help us constantly improve our service.</p>
              </DialogContentText>
              
              <br />

              <TextField
                autoFocus
                label="Feature request / feedback"
                variant="outlined"
                onChange={this.changeHandler}
                rows="4"
                required
                multiline
                fullWidth
              />
            
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" className="no-outline">
                Cancel
              </Button>
              <Button type="submit" color="primary" className="no-outline" disabled={submitDisabled}>
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default FeedbackDialog
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom:-10,
    marginTop: -10,
    padding: 0,
    color: 'gray',
  },
  content: {
    '&$expanded': {
      margin: '6px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    color: 'gray',
  },
}))(MuiExpansionPanelDetails);

const Collapse = ({label, value}) => {
  return (
    <div>
      <ExpansionPanel>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{fontSize: 12}}>{label} <ExpandMoreIcon/></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography style={{fontSize: 12, wordBreak: 'break-all'}}>{value}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default Collapse
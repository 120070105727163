import React, {Component} from "react";
import {Line} from "react-chartjs-2";
import {Container} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { showSnack } from './SnackBar'
import EventIcon from '@material-ui/icons/Event';
import Collapse from './Collapse'
import StatBox from './StatBox'
import CurrencyFormat from 'react-currency-format';
import LoadingBar from './LoadingBar';
import axios from 'axios'
import OptionsDropdown from './OptionsDropdown'
import ActivityItem from './ActivityItem'
import Grid from "@material-ui/core/Grid";
import MaterialLink from '@material-ui/core/Link';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RefreshButton from './RefreshButton'

const moment = require('moment');
export class ShowCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: '',
            isLoaded: false,
            buttonDisabled: false,
            graphData: [],
            graphMessage: '',
            orders: [],
            domain: '',
            graphLabel: '',
            graphColor: ''
        };
    }
 
    componentDidMount() {
        let campaignID = window.location.href.split('/').pop();
        fetch("/campaigns/"+campaignID, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then( result => {return result.json()})
            .then((result) => {
                    this.setState({
                        isLoaded: true,
                        campaign: result.campaign,
                        graphData: result.monthly_clicks,
                        orders: result.orders,
                        domain: result.domain,
                        graphLabel: '30-Day Clicks',
                        graphColor: '#EC932F'
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true
                    });
                }
            );
    }

    bitlyHandler = e => {
      this.setState({buttonDisabled: true});
      const { campaign } = this.state;
      axios.post('/campaigns/'+campaign.id+'/shorten_link', {})
        .then(res => {
          this.setState({ campaign: res.data.campaign,
                          buttonDisabled: false });

          showSnack('Link shortened with Bitly!', 'info')
        })
        .catch(error => {
          this.setState({buttonDisabled: false});
          showSnack(JSON.parse(error.request.response).message, 'danger')
        })
    };

    updateGraph = (options = {}) => {

      const { label, data, color } = options;

      this.setState({
        graphLabel: label, 
        graphData: data,
        graphColor: color
      })
    };

    render() {

        const refreshPage = () => {
          this.setState({isLoaded: false});
          window.location.reload();
        };
        
        const { graphData, graphLabel, graphColor } = this.state;

        if (this.state.isLoaded) {

            //Empty Defaults For Graph Don't Delete
            let data = {datasets: [{label: 'Clicks'}]};
            let options = {responsive: true, maintainAspectRatio: false};
            let plugins = [{}];

            if (graphData !== null) {
                 data = {
                    datasets: [{
                        label: graphLabel,
                        data: graphData.map((day) => day.count),
                        type: 'line',
                        fill: false,
                        borderColor: graphColor,
                        backgroundColor: graphColor,
                        pointBorderColor: graphColor,
                        pointBackgroundColor: graphColor,
                        pointHoverBackgroundColor: graphColor,
                        pointHoverBorderColor: graphColor,
                        yAxisID: 'y-axis-2'
                    }]
                };
                 options = {
                    responsive: true,
                     maintainAspectRatio: false,
                    tooltips: {
                        mode: 'label'
                    },
                    elements: {
                        line: {
                            fill: false
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                gridLines: {
                                    display: false
                                },
                                labels: graphData.map((day) => moment(new Date(day.date)).format("MM/DD")),
                                ticks: {
                                  maxTicksLimit: 10
                                }
                            }
                        ],
                        yAxes: [
                            {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                id: 'y-axis-2',
                                gridLines: {
                                    display: true
                                },
                                labels: {
                                    show: true
                                },
                                ticks: {
                                  beginAtZero: true,
                                  stepSize: 1,
                                  maxTicksLimit: 10,
                                  userCallback: function(value, index, values) {
                                    if(graphLabel === '30-Day Revenue'){
                                      value = `$${value}`
                                      return value;
                                    } else {
                                      return value;
                                    }
                                  }
                                }
                            }
                        ]
                    }
                };
            } else {
                plugins = [{
                    afterDraw: (chartInstance, easing) => {
                        const ctx = chartInstance.chart.ctx;
                    }
                }];
            }
            
            const { campaign, orders, domain } = this.state;
            const displayLink = campaign.short_link ? campaign.short_link : campaign.long_link;
        
        return (
                <Container className="campaign-show" maxWidth="lg">

                    <Grid  container
                           direction="column"
                           justify="center"
                           alignItems="stretch">
                        <Grid item>
                    <div>

                      <Button
                          size="small"
                          role="button"
                          className="no-outline"
                          component={Link}
                          to={{pathname: '/campaigns/'}}
                          startIcon={<ArrowBackOutlinedIcon/>}
                          >
                        All Campaigns
                      </Button>

                        <span className="float-right">
                          <RefreshButton classes="mr-4 hide-mobile" />
                          <OptionsDropdown campaign_id={campaign.id} />
                        </span>

                      <h2 className="mt-4">
                        {campaign.name}
                        <div className="label">
                            <EventIcon /> {moment(new Date(campaign.created_at)).format("MMMM DD, YYYY")}
                        </div>
                      </h2>
                    </div>
                        </Grid>

                        <Grid item>
                    <div className="clean-box">
                        <Line
                            data={data}
                            options={options}
                            plugins={plugins}
                            height={300}
                            
                        />
                    </div>
                        </Grid>

                    <Grid item>
                    { campaign.short_link_id === null ?
                      <div className="alert alert-warning">
                        <ErrorOutlineIcon /> To see clicks and conversion rate info, you must shorten your link.
                      </div> : null
                    }

                    <StatBox campaign={campaign} updateGraph={this.updateGraph} currentLabel={this.state.graphLabel} />
                    </Grid>

                        <Grid item>
                    <div className="clean-box">
                        <div className="label">Trackable Link</div>
                        <hr/>
                        
                        <MaterialLink  href={displayLink} target="_blank" className="value" style={{wordBreak: 'break-all'}}>
                            { this.state.buttonDisabled ?
                              <LoadingBar /> : displayLink
                            }
                        </MaterialLink>
                        

                        { campaign.short_link !== null ?
                          <Collapse label={"View long link"} value={campaign.long_link} /> : null
                        }
                        
                        <hr/>
                        { campaign.short_link === null ?
                          <Button variant="contained" disabled={this.state.buttonDisabled} onClick={this.bitlyHandler} className="no-outline custom-blue-btn mr-4" size="large">Shorten Link with
                              Bitly</Button> : null
                        }
                        <CopyToClipboard text={displayLink}
                                         onCopy={() => showSnack('Link copied to clipboard!', 'dark')}>
                            <Button variant="contained" color="default" size="large" className="no-outline"
                                    startIcon={<FileCopyOutlinedIcon/>}>
                                { campaign.short_link === null ? 'Copy Link' : 'Copy Short Link' }
                            </Button>
                        </CopyToClipboard>
                    </div>
                        </Grid>

                        <Grid item>
                    <div className="clean-box">
                        <div className="row text-center stat-box-2">
                            <div className="col-md-4">
                                <div className="label mb-3">Source</div>
                                <div style={{overflow: "scroll"}} className="number">{campaign.source}</div>
                            </div>
                            <div className="col-md-4 bar">
                                <div className="label mb-3">Goal</div>
                                <div className="number">{campaign.goal}</div>
                            </div>
                            <div className="col-md-4">
                                <div className="label mb-3">Cost</div>
                                { campaign.cost > 0 ?
                                  <CurrencyFormat decimalScale={2} fixedDecimalScale={true} className="number" value={campaign.cost} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 
                                  <div className="number">--</div>
                                }
                            </div>
                        </div>
                    </div>
                        </Grid>

                        <Grid item>
                    { campaign.description ? 
                      <div className="clean-box">
                          <div className="label">Description</div>
                          <hr/>
                          <div style={{fontSize: '14pt'}}>{campaign.description}</div>
                      </div> : null
                    }
                        </Grid>

                        <Grid item>
                    { orders.length > 0 ? 
                    <div className="clean-box" style={{fontSize: '14pt'}}>
                      <div className="label">Recent Campaign Activity</div>
                      {orders.map(order => <ActivityItem order={order} domain={domain} /> )}
                    </div> : null }
                        </Grid>
                    </Grid>
                </Container>
        )
        } else {
            return(
                <div className="loading-area container text-center">
                    <CircularProgress className="gray-loader" />
                </div>
            )
        }
    }
}
export default withRouter(ShowCampaign);









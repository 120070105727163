import React from 'react'
import {Container} from "@material-ui/core";
import axios from 'axios'
import SnackBar, { showSnack } from './SnackBar'
import Button from '@material-ui/core/Button';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      id: '',
      email: '',
      name: '',
      password: '',
      domain: ''
    };
  }

  componentDidMount() {
    axios.get('/profile.json')
      .then(res => {
        this.setState({ 
          email: res.data.user.email,
          name: res.data.user.name,
          domain: res.data.domain,
          id: res.data.user.id
        })
      })
      .catch(error => {
        showSnack('Something went wrong, please try again.', 'danger')
      })
  }

  changeHandler = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  submitHandler = e => {
    e.preventDefault()
    axios.put('/users/'+this.state.id, { user: { email: this.state.email,
                                                 name: this.state.name,
                                                 password: this.state.password }})
      .then(res => {
        this.setState({
          name: res.data.user.name,
          email: res.data.user.email, 
          password: ''
        })

        showSnack(res.data.message, 'success');
      })
      .catch(error => {
        showSnack(JSON.parse(error.request.response).message, 'danger')
      })
  }

  render(){

    const { name, email, password, domain } = this.state

    return(

      <Container className="profile" maxWidth="lg">
        <h2>Edit Profile</h2>
        
        <div className="clean-box">
          <form className="form-horizontal" onSubmit={this.submitHandler} >
            
            <div className="form-group">
              <label>Domain</label>
              <input type="textfield" 
                     name="domain" 
                     value={domain} 
                     className="form-control form-control-lg"
                     disabled />
            </div>

            <div className="form-group">
              <label>Name</label>
              <input type="textfield" 
                     name="name" 
                     value={name} 
                     className="form-control form-control-lg"
                     onChange={this.changeHandler} />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input type="email" 
                     name="email" 
                     value={email} 
                     className="form-control form-control-lg"
                     onChange={this.changeHandler} />
              <small className="text-muted">We will send important notifications here.</small>
            </div>

            <div className="form-group">
              <label>Password</label>
              <input type="password" 
                     name="password" 
                     value={password} 
                     className="form-control form-control-lg"
                     onChange={this.changeHandler} />
            </div>

            <Button variant="contained" type="submit" className="custom-green-btn no-outline mt-3" size="large">Save Changes</Button>

          </form>
        </div>
      
      </Container>

    )
  }
}

export default Profile
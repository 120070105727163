import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ee from 'event-emitter'

const emitter = new ee();

export const showSnack = (msg, color) => {
  emitter.emit('notification', msg, color);
}

class SnackBar extends React.Component {

  // if props open, then open, close no matter what after time
  constructor(props){
    super(props);

    this.state = {
      snackBarOpen: false,
      message: '',
      color: ''
    };

    emitter.on('notification', (msg, color) => {
      this.showHandler(msg, color);
    });
  }

  showHandler = (msg, color) => {
    this.setState({
      snackBarOpen: true,
      message: msg,
      color: color
    })
  }

  closeHandler = () => {
    this.setState({
      snackBarOpen: false
    })
  }

  render(){
    
    const { snackBarOpen, message, color } = this.state

    return (
      <Snackbar 
        autoHideDuration={5000}
        onClose={this.closeHandler}
        open={snackBarOpen}> 
        <SnackbarContent 
          className={'custom-snack snack-'+color}
          message={message}/>
      </Snackbar>
    )
  }
}

export default SnackBar
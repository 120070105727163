import React from 'react';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#3183C4',
  },
  barColorPrimary: {
    backgroundColor: 'lightgray',
  },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  bar: {
    marginTop: '25px',
    marginBottom: '25px',
    height: '10px'
  },
}));

const LoadingBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ColorLinearProgress className={classes.bar} />
    </div>
  );
}

export default LoadingBar
import React, { Component } from 'react';
import Typography from "@material-ui/core/Typography";
import {Animated} from "react-animated-css"
import Button from '@material-ui/core/Button';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import WarningIcon from '@material-ui/icons/Warning';
import axios from 'axios'
  
export class SidePannel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            name: '',
            path: '',
            url: '',
            source: '',
            goal: '', 
            cost: '',
            description: '',
            domain: '',
            buttonDisabled: false,
            message: '',
            productsList: []
        };

    }

    componentDidMount(){
      axios.get('/campaigns/new')
        .then(res => {
          this.setState({
            isLoaded: true, 
            domain: res.data.domain,
            productsList: res.data.products
          })
        })
        .catch(err => {
          this.setState({ isLoaded: true, 
                          message: 'Something went wrong. Please reload the page.'})
        })
    }

    changeHandler = e => {
       this.setState({[e.target.name]: e.target.value})
    };

    submitHandler = e => {
      e.preventDefault();
      this.setState({buttonDisabled: true});
      axios.post('/campaigns', { campaign: { name: this.state.name,
                                             url: this.state.url,
                                             source: this.state.source,
                                             goal: this.state.goal,
                                             cost: this.state.cost, 
                                             description: this.state.description } })
        .then(res => {
          window.location.href = "/campaigns/"+res.data.campaign.id;
        })
        .catch(err => {
          this.setState({buttonDisabled: false,
                         message: JSON.parse(err.request.response).message});
          this.errorMessage.scrollIntoView({ behavior: "smooth" });
        })
    };

    render() {
        const state = this.state;

        if (!state.isLoaded) {
            return  (
                <div className="loading-area container text-center">
                  <CircularProgress />
                </div>
            )
        } else {
            
            return (
                <React.Fragment>
                  <form onSubmit={this.submitHandler} className="container new-campaign" style={{width: 500}}>
                        
                    <CloseIcon className="close-icon" onClick={this.props.toggleDrawer('right', false)}/>
                    <Typography className="title text-muted" gutterBottom>
                       New Link Campaign
                    </Typography>
                    <hr ref={(el) => { this.errorMessage = el; }} />
                    
                    { this.state.message ? 
                      <div className="alert alert-danger">
                        <WarningIcon className="mr-1" /><strong>Error:</strong> {this.state.message}
                      </div> : null
                    }

                    <div className="form-area">
                        <Typography variant="h6" gutterBottom>
                            Name
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            name="name"
                            id="standard-required"
                            label="Name your campaign"
                            margin="none"
                            variant="outlined"
                            onChange={this.changeHandler}
                            autoFocus={true}
                        />

                    </div>

                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut"
                              animationInDuration={800} animationOutDuration={800} isVisible={state.name}>
                        
                      <div className="form-area">
                        <Typography variant="h6" gutterBottom>
                          URL
                        </Typography>

                        <Autocomplete
                          freeSolo
                          id="free-solo-demo"
                          options={state.productsList}
                          onChange={(event, value) => value ? this.setState({ url: state.domain+'/products/'+value.handle }) : null }
                          getOptionLabel={option => state.domain+'/products/'+option.handle}
                          renderOption={option => (
                            <React.Fragment>
                              <img src={option.images.length > 0 ? option.images[0].src : null} width="30" height="30" className="mr-2 rounded img-fluid" />
                              {option.title}
                            </React.Fragment>
                          )}
                          renderInput={params => (
                            <TextField {...params} label="Paste url or select product" 
                                                   variant="outlined"
                                                   name="url"
                                                   onChange={this.changeHandler} 
                                                   fullWidth
                                                   required 
                                                   InputProps={{
                                                     startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                                   }}/>
                          )}
                        />
                      </div>
                    </Animated>


                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut"
                              animationInDuration={800} animationOutDuration={800} isVisible={state.url && state.name}>
                        <div className="form-area">
                          <Typography variant="h6" gutterBottom>
                              Source
                          </Typography>
                          <TextField
                              required
                              fullWidth
                              id="standard-required"
                              label="Campaign source"
                              name="source"
                              helperText="e.g. Email, YouTube, Instgram, etc."
                              margin="none"
                              variant="outlined"
                              onChange={this.changeHandler}
                          />

                          
                      </div>
                    </Animated>

                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut"
                              animationInDuration={800} animationOutDuration={800} isVisible={state.source && state.url && state.name}>
                        <div className="form-area">
                          <Typography variant="h6" gutterBottom>
                              Goal
                          </Typography>
                          <TextField
                              required
                              fullWidth
                              id="standard-required"
                              label="Campaign goal"
                              name="goal"
                              helperText="What is the goal (e.g. sales, clicks, etc)?"
                              margin="none"
                              variant="outlined"
                              onChange={this.changeHandler}
                          />

                          
                      </div>
                    </Animated>

                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut"
                              animationInDuration={800} animationOutDuration={800} isVisible={state.goal && state.source && state.url && state.name}>
                        <div className="form-area">
                          <Typography variant="h6" gutterBottom>
                              Cost / Spending <span className="text-muted">(optional)</span>
                          </Typography>
                          <FormControl fullWidth variant="outlined">
                            
                            <OutlinedInput
                              id="outlined-adornment-amount"
                              onChange={this.changeHandler}
                              name="cost"
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              labelWidth={0}

                            />
                            <FormHelperText id="standard-weight-helper-text">Used to calculate profitability</FormHelperText>
                          </FormControl>

                          
                      </div>
                    </Animated>


                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut"
                              animationInDuration={800} animationOutDuration={800} isVisible={state.goal && state.source && state.url && state.name}>
                        <div className="form-area">
                          <Typography variant="h6" gutterBottom>
                              Description <span className="text-muted">(optional)</span>
                          </Typography>
                          <TextField
                              multiline
                              fullWidth
                              id="standard-required"
                              label="Campaign description"
                              name="description"
                              helperText="For your reference only"
                              rows="4"
                              margin="none"
                              variant="outlined"
                              onChange={this.changeHandler}
                          />

                          
                      </div>
                    </Animated>

                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut"
                              animationInDuration={800} animationOutDuration={800} isVisible={state.goal && state.source && state.url && state.name}>
                      <Button disabled={this.state.buttonDisabled} type="submit" fullWidth className="no-outline custom-blue-btn mt-5 mb-3" variant="contained" size="large">Submit</Button>
                    </Animated>
                  

                  </form>
                </React.Fragment>
            );
        }
    }
}

export default SidePannel;
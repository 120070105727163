import React from 'react'

const ConversionRate = ({clicks, orders}) => {
  var rate = 0;

  if (clicks > 0){
    rate = (orders/clicks) * 100
  }

  if (rate > 100){
    rate = 100
  } else {
    rate = Math.round(rate)
  }

  return(
    <span>{rate}%</span>
  );
}

export default ConversionRate
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SidePannel from "./SidePannel";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import axios from "axios";
import SnackBar from './SnackBar'
import FeedbackDialog, { openFeedback } from './FeedbackDialog'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    text: {
        color: '#000000',
    },
    mobileHeader: {
        background: '#FFD643'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            background: '#FFD643',
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        background: '#e0e0e0',
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    sideDrawer: {
        maxWidth: 500,
    },
    activeListItem: {
        background: '#FFD643',
        '&:hover': {
          background: '#FFD643'
        }
    },
    inActiveListItem: {
        background: '#e0e0e0'
    }

}));

const themeInstance = {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
};

const csrf = window.sessionStorage.getItem("csrf");

const goToHomePage = () => {
    window.location.href = '/';
};

const logOut = async () => {
    const response = await fetch('/logout', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrf
            }
        });
        goToHomePage()
    };

function ResponsiveDrawer(props) {
    const wrapper = props;
    const key = wrapper.selected;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const [feedbackBody, setFeedbackBody] = React.useState({feedbackBody: ""});
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeHandler = (e) => {
        setFeedbackBody(e.target.value);
    };

    const submitFeedback = feedbackBody => {
        axios.post('/feature-request', {
            feature_request:
                {
                    body: feedbackBody
                }
        }).then(res => {
            console.log(res);
            setOpen(false);
        })
            .catch(err => {
            })
    };

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open,  });
    };

        const sideList = side => (
            <div
                className={classes.sideDrawer}
                role="presentation"
            >
              <SidePannel toggleDrawer={toggleDrawer} />
            </div>
        );

        const drawer = (
            <div>
                <div className={classes.toolbar}/>
                <Divider/>
                <List className="sidebar-list">
                    <ListItem onClick={toggleDrawer('right', true)} button key={'+ New Link'} >
                            <AddOutlinedIcon className="br-2" /> New Campaign
                    </ListItem>
                    <Divider/>
                    <ListItem className={(key === 'all' ? classes.activeListItem : classes.inActiveListItem)} button key={'Analytics'} component={Link} to={{pathname:'/campaigns'}} >
                            Campaigns
                    </ListItem>
                    <Divider/>
                    <ListItem className={(key === 'subscription' ? classes.activeListItem : classes.inActiveListItem)} component={Link} to={{pathname: '/subscription'}} button key={'Billing'}>
                            Billing
                    </ListItem>
                    <Divider/>
                    <ListItem className={(key === 'profile' ? classes.activeListItem : classes.inActiveListItem)} component={Link} to={{pathname: '/profile'}} button key={'Account'}>
                            Account
                    </ListItem>
                    
                    <Divider/>
                  </List>
                    
                  <List className="sidebar-list bottom-list">

                    <Divider/>
                    <ListItem className={classes.inActiveListItem} onClick={() => openFeedback()} button >
                        Feature Request
                    </ListItem>

                    <Divider/>
                    <ListItem onClick={logOut} button key={'Logout'}>
                      Logout <ExitToAppIcon className="ml-1 text-muted"/>
                    </ListItem>
                    
                </List>
            </div>
        );

  return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.mobileHeader}>
                    <IconButton
                        color="#000000"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography className={classes.text} variant="h6" noWrap style={{flex: 1}}>
                        LinkTrack.io
                    </Typography>

                    <Button color="default" className="no-outline" variant="outlined" onClick={toggleDrawer('right', true)} startIcon={<AddOutlinedIcon className="br-2" />}>New Campaign</Button>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="navigation">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
                        {sideList('right')}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {wrapper.wrapper}
                <SnackBar />
                <FeedbackDialog />
            </main>
        </div>
    );
}

export default ResponsiveDrawer;